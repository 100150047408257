'use client';
import { Icon } from '../../Icon';
import styles from './ScrollTopButton.module.scss';

export default function ScrollTopButton() {
  return (
    <button
      className={styles.button}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
    >
      <Icon name="chevronUp" />
      <span>back to top</span>
    </button>
  );
}
