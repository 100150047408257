'use client';
import { useContext } from 'react';
import { AppContext } from '../../../context/AppContext';
import { Script } from '../../UI/Script';

export const AdDefend = ({ script }: { script: string }) => {
  const {
    pageMetaData: { noAds },
  } = useContext(AppContext);
  if (noAds || script === '') {
    return null;
  }

  return <Script script={script} />;
};
