'use client';
import type { PropsWithChildren } from 'react';
import type { FeatureFlags } from 'api/getFeatureFlags';
import { ConsentProvider } from 'components/lib/Sourcepoint';
import { AppProvider } from 'context/AppContext';
import type { AdSizePredictionProps } from 'types/ads.d';
import type { BasisPageData, StructureTypes } from 'types/cms';
import type { TickarooPrefetchData } from 'types/storylines';

export type ProvidersProps = PropsWithChildren<{
  readonly pageType?: StructureTypes;
  readonly pageTitle?: string;
  readonly rawPageData?: BasisPageData['areas'];
  readonly adSizePrediction?: AdSizePredictionProps;
  readonly prefetchedData?: TickarooPrefetchData;
  readonly requestPath?: string;
  readonly featureFlags?: FeatureFlags;
}>;

export function Providers({
  children,
  pageType,
  pageTitle,
  rawPageData,
  adSizePrediction,
  prefetchedData,
  requestPath,
  featureFlags,
}: ProvidersProps) {
  return (
    <AppProvider
      value={{
        pageType,
        pageTitle,
        rawPageData,
        adSizePrediction,
        prefetchedData,
        requestPath,
        featureFlags,
      }}
    >
      <ConsentProvider>{children}</ConsentProvider>
    </AppProvider>
  );
}
