'use client';
import { useState, useEffect } from 'react';
import Script from 'next/script';
import { useQueryParam } from '../../../customHooks/useQueryParam';

export const MoltenBundle = () => {
  const isGujAdPreview = !!useQueryParam('gujAd');
  const [pageLoaded, setPageLoaded] = useState(false);
  const [isTopScriptLoaded, setIsTopScriptLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  if (pageLoaded) {
    return (
      <>
        <Script
          id="molten-top"
          src={
            isGujAdPreview
              ? 'https://adctrl.emsmobile.de/molten/preview/neu.rtl.de/MoltenBundle.top.js'
              : 'https://www.rtl.de/mbundle/top.js'
          }
          onLoad={() => setIsTopScriptLoaded(true)}
          async
        />

        {isTopScriptLoaded && (
          <Script
            id="molten-bottom"
            strategy="afterInteractive"
            src={
              isGujAdPreview
                ? 'https://adctrl.emsmobile.de/molten/preview/neu.rtl.de/MoltenBundle.bottom.js'
                : 'https://www.rtl.de/mbundle/bottom.js'
            }
            async
          />
        )}
      </>
    );
  }

  return null;
};
