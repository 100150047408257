'use client';
import { useContext } from 'react';
import Script from 'next/script';
import { ConsentContext } from '../Sourcepoint';

/**
 * netID consent management integration
 */
export const NetID = () => {
  const { isSourcepointEnabled } = useContext(ConsentContext);

  if (!isSourcepointEnabled) {
    return null;
  }

  return (
    <>
      <Script defer src="https://te-static.technical-service.net/netid/rtlde.min.js" />
      <Script
        id="netID-tracking"
        dangerouslySetInnerHTML={{
          __html: `(function() {
            var events = [
                { e: 'netid-show', nonInteraction: true, event: 'netid_layer_shown', },
                { e: 'netid-accept', noninteraction: false, event: 'netid_layer_clicked', option: 'accept' },
                { e: 'netid-decline', noninteraction: false, event: 'netid_layer_clicked', option: 'decline' },
                { e: 'netid-later', noninteraction: false, event: 'netid_layer_clicked', option: 'later' },
                { e: 'netid-close', noninteraction: false, event: 'netid_layer_clicked', option: 'close' },
            ]
            events.forEach(function(t) {
                window.addEventListener(t.e, function(e) {
                    var m = events.find(function(k) { return k.e === e.type })
                    window.dataLayer.push({
                        'event': m.event,
                        'option_selected': m.option || 'empty',
                        'non_interaction': m.nonInteraction,
                    })
                })
            })
        })()`,
        }}
      />
    </>
  );
};
