'use client';

import { useContext, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { AppContext, PageType } from '../../../context/AppContext';
import { AdAllianceLib } from './adAllianceLib';

export const AdAlliance = () => {
  const {
    pageType,
    pageMetaData: { adZone, keywords },
  } = useContext(AppContext);

  const pathname = usePathname();
  const currentAdZone = pathname === '/' ? 'homepage' : adZone;

  useEffect(() => {
    AdAllianceLib.initialize({
      adZone: currentAdZone,
      keywords: keywords,
      pageType: pageType === 'article' ? PageType.Article : PageType.Overview,
    });
  }, [keywords, pageType, currentAdZone]);

  return null;
};
